import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import Header from "../components/header"
import { Row } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const ContactPage = (): JSX.Element => {
  const intl = useIntl()

  return <Layout>
    <SEO
      title={intl.formatMessage({ id: 'navbar.contact' })}
    />
    <Header
      title={intl.formatMessage({ id: 'navbar.contact' })}
      subtitle={intl.formatMessage({ id: 'contact.subtitle' })}
    />
    <Row className="bg-white" noGutters>
      <ContactForm />
    </Row>
  </Layout>
}

export default ContactPage
