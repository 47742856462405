import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container } from "react-bootstrap"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Message = ({ content, success }) => (
  <div
    className="alert alert-success mt-3 form-alert"
    style={success ? { visibility: "visible" } : { visibility: "hidden" }}
  >
    {content}
  </div>
)

const INITIAL_STATE = { name: "", email: "", message: "" }
const ContactForm = (): JSX.Element => {
  const [state, setState] = React.useState(INITIAL_STATE)
  const [success, setSuccess] = React.useState(false)
  const intl = useIntl()

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "email-form",
        ...state,
      }),
    }).then(() => setSuccess(true))
    setState(INITIAL_STATE)
    setTimeout(() => {
      setSuccess(false)
    }, 3000)
    e.preventDefault()
  }

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value })

  return (
    <Container>
      <div className="contact-page-form m-auto py-5">
        <form
          onSubmit={handleSubmit}
          id="email-form"
          name="email-form"
          data-name="Email Form"
          data-netlify="true"
          method="post"
        >
          <label htmlFor="user_name">
            {intl.formatMessage({ id: 'contact.nameSurname' })}
          </label>
          <input
            type="text"
            id="user_name"
            name="name"
            maxLength={256}
            onChange={onChange}
            value={state.name || ""}
            required
          />
          <label htmlFor="user_email">{intl.formatMessage({ id: 'contact.email' })}</label>
          <input
            type="email"
            id="user_email"
            name="email"
            maxLength={256}
            onChange={onChange}
            value={state.email || ""}
            required
          />
          <label htmlFor="user_message">{intl.formatMessage({ id: 'contact.message' })}</label>
          <textarea
            name="message"
            maxLength={5000}
            id="user_message"
            onChange={onChange}
            value={state.message || ""}
            required
          />
          <button type="submit" className="contact-page-form-button d-block">
            {intl.formatMessage({ id: 'contact.send' })}
          </button>
        </form>
        {success && (
          <Message
            success={success}
            content={intl.formatMessage({ id: 'contact.thank' })}
          />
        )}
      </div>
    </Container>
  )
}

export default ContactForm
